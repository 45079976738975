<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container class="pt-0">
      <v-row
        class="pa-2"
      >
        <v-col
          cols="12"
          align="right"
        >
          <v-btn
            depressed
            color="primary"
            @click="onClickReturnEnd"
          >
            반송 완료
          </v-btn>
        </v-col>
        <v-spacer />
      </v-row>
      <dx-data-grid
        ref="refReturnDetailGrid"
        :data-source="deliveryDetailListInfo"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="발주번호"
        :focused-row-key.sync="focusedRowKeyReturn"
        @focused-row-changed="onSelectionChangedDetail"
        @cell-prepared="onCellPrepared"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxColumnFixing :enabled="false" />
        <DxColumn
          :allow-editing="false"
          caption="NO"
          data-field="sitelogisticstaskid"
          sort-order="asc"
          :min-width="50"
        />
        <DxColumn
          :allow-editing="false"
          caption="품번"
          data-field="품번"
          :min-width="114"
        />
        <DxColumn
          :allow-editing="false"
          caption="계획"
          data-field="오더수량"
          :min-width="64"
        />
        <DxColumn
          :allow-editing="false"
          caption="반품"
          data-field="입고수량"
          :min-width="64"
        />
        <DxColumn
          caption="취소"
          width="50"
          :buttons="editButtons"
          type="buttons"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="nmOrdpsn"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="onClickBefore"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          불량 반품 등록
        </v-toolbar-title>
      </v-app-bar>
      <div
        v-if="selectedRowDetail"
        class="left-draw-content"
      >
        <v-container>
          <div class="content-input-wrap">
            <div
              v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
              class="qrcode-wrap"
              :style="{display: showQrScaner}"
            >
              <QrCodeScanComponent @scan-result="onPCodeScan" />
            </div>
            <v-row
              class="pb-0"
              dense
            >
              <v-col
                cols="7"
              >
                <v-text-field
                  v-model="selectedRowDetail.sitelogisticstaskid"
                  label="반품ID"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
              <v-col
                cols="5"
              >
                <v-text-field
                  v-model="selectedRowDetail.오더수량"
                  label="반품수량"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row
              class="mt-0 pt-0"
              dense
            >
              <v-col
                class="mt-0 pt-0"
                cols="7"
              >
                <v-text-field
                  v-model="selectedRowDetail.품번"
                  label="품번"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
              <v-col
                class="mt-0 pt-0"
                cols="5"
              >
                <v-text-field
                  v-model="selectedRowDetail.identifiedstockid"
                  label="동종재고ID"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="selectedRowDetail.품명"
              label="품명"
              :readonly="true"
              filled
              autocomplete="off"
            />
          </div>
          <v-row dense>
            <v-col
              :cols="$store.getters.getMobileModel !== 'PDA' ? 5 : 6"
            >
              <v-text-field
                id="input-qrkey"
                v-model="qrKey"
                label="파레트번호"
                placeholder="QR 스캔"
                clearable
                autocomplete="off"
                @keyup.enter.prevent="onEnterPcode"
              />
            </v-col>
            <v-col
              v-if="$store.getters.getMobileModel !== 'PDA'"
              class="pb-0 pt-4 pl-0"
              cols="1"
            >
              <v-icon
                @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
              >
                mdi-camera
              </v-icon>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
            >
              <v-text-field
                id="input-qty"
                v-model="takeQty"
                label="실반품수량"
                type="number"
                clearable
                autocomplete="off"
                @keyup.enter.prevent="onEnterTakeQty"
              />
            </v-col>
          </v-row>
          <div style="margin-top:10px; background-color:black;">
            <dx-data-grid
              ref="refGoodsInputGrid"
              :data-source="goodsInputListInfo"
              :show-borders="true"
              :show-row-lines="false"
              :show-column-lines="true"
              :hover-state-enabled="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              height="160"
              :focused-row-enabled="true"
              :focused-row-key.sync="focusedRowKey"
              :auto-navigate-to-focused-row="false"
              key-expr="seqId"
              @focused-row-changed="onSelectionChanged"
            >
              <DxColumn
                :allow-editing="false"
                caption="NO"
                data-field="입고키"
                sort-order="desc"
                width="60"
              />
              <DxColumn
                :allow-editing="false"
                caption="품번"
                data-field="품번"
                width="130"
              />
              <DxColumn
                :allow-editing="false"
                caption="동종재고"
                data-field="동종재고코드"
                width="110"
              />
              <DxColumn
                :allow-editing="false"
                caption="잔량"
                data-field="현재수량"
                format="#,##0"
                width="80"
              />
              <DxColumn
                :allow-editing="true"
                caption="유효일"
                data-field="유효일"
                data-type="date"
                format="yy/MM/dd"
                width="100"
              />
              <DxColumn
                :allow-editing="false"
                caption="품명"
                data-field="품명"
                width="120"
              />
              <dx-paging :enabled="false" />
              <dx-scrolling
                mode="virtual"
              />
              <dx-sorting mode="multiple" />
            </dx-data-grid>
          </div>
          <v-row
            dense
            class="mt-2"
          >
            <v-col
              cols="12"
            >
              <v-btn
                width="100%"
                color="primary"
                @click="onClickSave"
              >
                반품 등록
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import DxDataGrid, {
  DxScrolling, DxTotalItem, DxSearchPanel, DxColumnFixing,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import axios from 'axios'
import HttpService from '../../share/service/HttpService'

export default {
  name: 'ProductionReturn',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxColumnFixing,
    QrCodeScanComponent
  },
  props: {
    returnType: {
      type: String,
      default: 'BC'
    }
  },
  data: function () {
    return {
      pikingDate: null,
      isLoading: false,
      userInfo: null,
      htmlWdth: 800,
      modal: false,
      datePickerWidth: '350px',
      gridHeight: null,
      selectedInType: {},
      inTypes: [],
      deliveryDetailListInfo: null,
      focusedRowKeyReturn: null,
      selectedRowDetail: null,
      editButtons: [
        {
          hint: '삭제',
          icon: 'trash',
          visible: 'true',
          onClick: this.onClickDelete
        }],
      // qrcode scan
      showQrScaner: 'none',
      qrKey: null,
      goodsInputListInfo: null,
      focusedRowKey: null,
      takeQty: 0,
      selectedRow: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.returnDate = AppLib.getToday()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 120)
    this.htmlWdth = this.$Q('html').width()
    window.addEventListener('resize', this.handleResize)
    this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
    this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
    this.refreshDelivery()
  },
  updated () {
  },
  methods: {
    handleResize (event) {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
      this.gridHeight = (this.$Q('html').height() - 120)
    },
    onCellPrepared (e) {
      if (e.rowType === 'data') {
        if (e.column.type !== undefined) {
          if (e.row.data.입고등록 === false || e.row.datataskInputEnd) {
            this.$Q(e.cellElement).css('display', 'none')
          }
        }
        if (e.column.index === 0) {
          if (e.row.data.입고등록 === true || e.row.data.taskInputEnd) {
            this.$Q(e.cellElement).css('color', 'red')
          }
        }
      }
    },
    onClickDelete (e) {
      console.log(e.row.data)
      if (!e.row.data.입고등록) {
        this.$snotify.info('등록된 반품정보가 없습니다.')
        return
      }
      if (e.row.data.taskInputEnd) {
        this.$snotify.error('현재 품목은 반품처리가 완료 되어 삭제할 수 없습니다.')
        return
      }
      this.$snotify.confirm('반품 정보를 삭제 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '삭제',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.onRowRemoved(e.row)
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onRowRemoved (row) {
      this.isLoading = true
      const updateParam = {
        발주번호: row.data.발주번호,
        입고등록: false
      }
      const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, [updateParam])
      const receipt = this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
      axios.all([receipt, order])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          row.data.입고수량 = 0
          row.data.입고등록 = false
          this.$refs.refReturnDetailGrid.instance.refresh()
          this.focusedRowKeyReturn = null
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onClickReturnEnd () {
      if (this.deliveryDetailListInfo.length === 0) return
      this.$snotify.confirm('반품 완료 처리 하시겠습니까?(완료된 항목은 반품 취소할 수 없습니다.)', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '완료',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.returnCompleted()
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    returnCompleted () {
      console.log(this.deliveryDetailListInfo)
      const inList = this.$_.where(this.deliveryDetailListInfo, { 입고등록: true, taskInputEnd: false })
      console.log(inList)
      const taskId = this.$_.groupBy(inList, 'sitelogisticstaskid')
      console.log(taskId)
      const sendTackId = []
      let noCompleted = ''
      for (const value of Object.keys(taskId)) {
        console.log(value)
        const array = this.$_.where(this.deliveryDetailListInfo, { sitelogisticstaskid: value })
        console.log(array)
        for (let i = 0; i < array.length; i++) {
          console.log(array[i].입고등록)
          if (array[i].입고등록 === false) {
            noCompleted += `${array[i].sitelogisticstaskid} `
            break
          }
        }
        for (let i = 0; i < array.length; i++) {
          const temp = {
            발주번호: array[i].발주번호,
            taskInputEnd: true
          }
          sendTackId.push(temp)
        }
      }

      if (sendTackId.length > 0) {
        if (noCompleted.length > 0) {
          this.$snotify.info(`그릅(${noCompleted})중 반품처리가 되지 않은 품목이 있습니다.`)
          return
        }
        const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, sendTackId)
        const stock = this.$_sp.runUpdateSqlProc('창고수불', ['발주번호'], null, sendTackId)
        axios.all([order, stock])
          .then(axios.spread((...reponse) => {
            this.$snotify.info('저장 되었습니다.')
            HttpService.reqPost(null, '/updatePurchaseReturn')
            setTimeout(() => {
              this.refreshDelivery()
            }, 800)
          }))
      } else {
        this.$snotify.info('모든 반품항목이 이미 완료 처리 되었습니다.')
      }
    },
    onSelectionChangedDetail (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        if (e.row.data.입고등록 > 0) {
          this.$snotify.info('이미 반품등록이 완료된 항목 입니다.')
          return
        }
        if (e.row.data.입고등록 === true && e.row.data.taskInputEnd) {
          this.$snotify.info('반품완료 처리 되었습니다. 내역은 창고 입출 현황에서 조회 가능 합니다.')
          return
        }
        if (e.row.data.입고등록) {
          this.$snotify.info('반품처리 되었습니다. 수정은 삭제후 재 입력하여 주세요.')
          return
        }
        this.selectedRowDetail = e.row.data
        this.takeQty = this.selectedRowDetail.오더수량
        const vm = this
        this.$Q('.left-draw-wrap').animate({
          left: '0'
        }, 800, function () {
          vm.$Q('#left-draw-app-bar').css('position', 'fixed')
          vm.$Q('.left-draw-content').css('padding-top', '50px')
          vm.$Q('#input-qrkey').focus()
          const html = vm.$Q('html').height()
          const drawContentHeight = vm.$Q('.content-input-wrap').height()
          vm.gridHeightSub = html - drawContentHeight - 60
          vm.focusedRowKeyOrder = null
          vm.$Q('#input-qrkey').focus()
        })
      }
    },
    refreshDelivery () {
      this.isLoading = true
      this.isLoading = true
      const param = {
        OpTypeStart: '23',
        OpTypeEnd: '24'
      }

      this.$_sp.runNoEncodeFindProc('spFindAllPurchaseOrderByOpType', param)
        .then((data) => {
          this.isLoading = false
          this.deliveryDetailListInfo = this.$_sp.MakeModel(data)
          console.log(this.deliveryDetailListInfo)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })

      /*
      const param = {
        type: this.returnType
      }
      this.$_sp.runNoEncodeFindProc('spFindAllReturnOrderByType', param)
        .then((data) => {
          this.isLoading = false
          this.deliveryDetailListInfo = this.$_sp.MakeModel(data)
          console.log(this.deliveryDetailListInfo)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
        */
    },
    // left draw view
    // left slide view
    initInput () {
      this.qrKey = null
      // this.takeQty = null
      this.focusedRowKey = null
      this.goodsInputListInfo = null
    },
    onEnterPcode (e) {
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey)
      this.goodsInputListInfo = null
      this.qrKey = split
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptByKeyAndInput', { 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
        .then((data) => {
          const temp = this.$_sp.MakeModel(data)
          const where = this.$_.findWhere(temp, { 품번: this.selectedRowDetail.품번, 동종재고코드: this.selectedRowDetail.identifiedstockid })
          console.log(temp)
          if (where === undefined) {
            this.$snotify.info('해당 파레트번호로 검색된 품목(동종재고ID)이 없습니다.')
            this.initInput()
          } else {
            this.goodsInputListInfo = temp
            this.focusedRowKey = where.seqId
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterTakeQty (e) {

    },
    onClickBefore () {
      this.focusedRowKeyReturn = null
      this.$Q('#left-draw-app-bar').css('position', 'static')
      this.$Q('.left-draw-content').css('padding-top', '0px')
      this.initInput()
      this.$Q('.left-draw-wrap').animate({
        left: `${this.htmlWdth}px`
      }, 500)
    },
    onClickSave () {
      if (this.focusedRowKeyReturn === null) {
        this.$snotify.error('파레트 정보를 검색하여 주세요.')
        this.$Q('#input-qrkey').focus()
      }
      if (this.takeQty === 0) {
        this.$snotify.error('반품수량을 입력 하여주세요.')
        this.$Q('#input-qty').focus()
        return
      }
      const param = {
        입고키: this.qrKey,
        품번: this.selectedRowDetail.품번,
        수량: this.takeQty,
        동종재고코드: this.selectedRow.identifiedstockid,
        로트번호: this.selectedRow.로트번호,
        유효일: this.selectedRow.유효일,
        장소위치코드: this.selectedRow.장소위치코드,
        발주번호: this.selectedRowDetail.발주번호,
        입출고구분: ConstDef.불량품출고
      }
      const updateParam = {
        발주번호: this.selectedRowDetail.발주번호,
        입고등록: true
      }
      this.isLoading = true

      const order = this.$_sp.runUpdateSqlProc('구매발주서OUT', ['발주번호'], null, [updateParam])
      const receipt = this.$_sp.runInsertSqlProc('창고수불', null, [param])
      axios.all([receipt, order])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          this.selectedRowDetail.입고등록 = true
          this.selectedRowDetail.입고수량 = this.takeQty
          this.$refs.refReturnDetailGrid.instance.refresh()
          this.$snotify.info('저장 되었습니다.')
          this.initInput()
          setTimeout(() => {
            this.onClickBefore()
          }, 100)
        }))
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    }
  }
}
</script>

<style lang="scss">
  .left-draw-content {
    background-color: black;
  }
</style>
